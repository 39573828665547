import httpClient from "./http-client";

const ENDPOINT = "/configuracion/talla";

export interface TallaCreateDto {
  id?: number;
  nombre: string;
  descripcion: string;
}

class TallaService {
  getAll(): Promise<TallaCreateDto[]> {
    return httpClient.get(ENDPOINT).then(response => {
      if (response.data) {
        return response.data;
      }
    });
  }
  save(createDto: TallaCreateDto) {
    return httpClient.post(ENDPOINT, createDto);
  }
}
export default new TallaService();
