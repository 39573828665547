














































import TallaAdd from "@/components/admin/evento/TallaAdd.vue";
import Vue from "vue";
import TallaService, { TallaCreateDto } from "@/api/TallaService";

export default Vue.extend({
  components: {
    TallaAdd
  },
  metaInfo: {
    title: "Tallas"
  },
  data() {
    return {
      dialog: false,
      dato: {
        nombre: "",
        descripcion: "",
        edadMinima: 0,
        edadMaxima: 0,
        position: 0
      } as TallaCreateDto,
      headers: [
        { text: "Id", value: "id" },
        { text: "Nombre", value: "nombre" }, // disp
        { text: "Descripción", value: "descripcion" }, // disp
        { text: "Edad Min", value: "edadMinima" },
        { text: "Edad Max", value: "edadMaxima" },
        { text: "Pos.", value: "position" },
        //{ text: "Estado", value: "estado" },
        //{ text: "createdOn", value: "createdOn", sortable: false },
        { text: "Acción", value: "actions", sortable: false }
      ],
      datos: [] as TallaCreateDto[]
    };
  },
  methods: {
    setDefaultItem() {
      const newDto = {
        nombre: "",
        descripcion: "",
        edadMinima: 0,
        edadMaxima: 0,
        position: 0
      };
      this.dato = newDto;
    },
    editItem(item: TallaCreateDto) {
      this.dato = item;
      this.dialogChange(true);
    },
    newItem() {
      this.setDefaultItem();
      this.dialogChange(true);
    },
    dialogChange(value: boolean) {
      this.dialog = value;
    },
    getAll() {
      TallaService.getAll()
        .then(response => {
          this.datos = response;
        })
        .catch(() => {
          this.$swal({
            toast: true,
            position: "center",
            showConfirmButton: false,
            timer: 2000,
            icon: "error",
            title: "Tallas",
            text: "No se pudo obtener"
          });
        });
    }
  },
  mounted() {
    this.getAll();
  },
  watch: {
    dialog: function(newVal) {
      if (newVal === false) {
        this.setDefaultItem();
      }
    }
  }
});
