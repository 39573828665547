









































































import Vue from "vue";
import TallaService from "@/api/TallaService";
export default Vue.extend({
  props: ["dialog", "dato"],
  data() {
    return {
      editedIndex: -1,
      editedItem: {
        nombre: "",
        descripcion: ""
      }
    };
  },
  mounted() {
    this.editedItem = { ...this.dato };
  },
  methods: {
    dialogChange(value: boolean) {
      this.$emit("dialogChange", value);
    },
    close() {
      this.dialogChange(false);
    },
    save() {
      TallaService.save(this.editedItem)
        .then(() => {
          this.close();
          this.$emit("saved", "someValue");
          this.$swal({
            toast: true,
            position: "bottom-right",
            showConfirmButton: false,
            timer: 4000,
            icon: "success",
            title: "Talla",
            text: "Creada exitosamente"
          });
        })
        .catch(() => {
          this.$swal({
            toast: true,
            position: "bottom-right",
            showConfirmButton: false,
            timer: 3000,
            icon: "error",
            title: "Talla",
            text: "No se pudo crear"
          });
        });
    }
  },
  computed: {},
  watch: {}
});
